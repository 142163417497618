/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react';
import { css, jsx } from '@emotion/react';

import Footer from './Footer';

const homePage = css({
  width: '100%',
  height: '100vh',
  backgroundColor: '#202020',
  color: '#8a0707',
  fontFamily: 'Ink Free, sans-serif',
  overflowY: 'scroll',
  overflowX: 'hidden',
});
const aboutMeBlock = css({
  alignItems: 'center',
  display: 'flex',
  margin: '10px 0px',
  flexDirection: 'column',
});
const personalFavorite = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
});
const headerStyle = css({
  fontSize: '3em',
  fontWeight: 'bold',
});
const bodyStyle = css({
  width: '50%',
  fontSize: '2em',
});
const videoGrid = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
  padding: '20px',
});
const videoHolder1 = css({
  width: '40%',
  margin: '40px',
});
const videoHolder2 = css({
  position: 'relative',
  paddingBottom: '56.25%',
});
const videoStyle = css({
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export default class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="home" css={homePage}>
        <div id="aboutMe" css={aboutMeBlock}>
          <p css={bodyStyle}>
            Hello everyone, Shadow Armada here, and I am glad you've found my official site.
            I am a 26 year old male who obtained a Bachelor of Science in Computer Science and now works
            in the industry. In addition to my full time job, I stream to Twitch as an affiliate
            for over 60 hours a month, have ran a weekly Dungeons & Dragons session, and created
            video games.
          </p>
        </div>
        <div id="personalFavorite" css={personalFavorite}>
          <div css={headerStyle}>Personal Favorites</div>
          <div css={videoGrid}>
            <div css={videoHolder1}>
              <div css={videoHolder2}>
                <iframe
                  src="https://youtube.com/embed/J7kZtSv-Dmw"
                  width="1920"
                  height="1080"
                  css={videoStyle}
                  frameBorder="0"
                  allowFullScreen
                >
                </iframe>
              </div>
            </div>
            <div css={videoHolder1}>
              <div css={videoHolder2}>
                <iframe
                  src="https://youtube.com/embed/5yDcuOB_QA0"
                  width="1920"
                  height="1080"
                  css={videoStyle}
                  frameBorder="0"
                  allowFullScreen
                >
                </iframe>
              </div>
            </div>
            <div css={videoHolder1}>
              <div css={videoHolder2}>
                <iframe
                  src="https://youtube.com/embed/ZL86a1fXzCs"
                  width="1920"
                  height="1080"
                  css={videoStyle}
                  frameBorder="0"
                  allowFullScreen
                >
                </iframe>
              </div>
            </div>
            <div css={videoHolder1}>
              <div css={videoHolder2}>
                <iframe
                  src="https://youtube.com/embed/R-5ahZmfjy0"
                  width="1920"
                  height="1080"
                  css={videoStyle}
                  frameBorder="0"
                  allowFullScreen
                >
                </iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
